@import "https://fonts.googleapis.com/css?family=Lato:100";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sam-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 100%;
  align-items: center;
}

h1 {
  font-size: 3.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 3px solid;
  padding: 3rem 7rem;
}

section {
  width: 100%;
  height: 100vh;
  color: #fff;
  background: linear-gradient(-45deg, #ee7752, #e73f7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  position: relative;
  animation: change 20s ease-in-out infinite;
}

i:hover {
  cursor: pointer;
}

a {
  margin: 0.7rem;
}

p {
  font-size: 0.8rem;
}

@keyframes change {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

div.absolute {
  position: absolute;
  width: 50%;
  bottom: 10px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background: #1e1e1e;
}

.center h3 {
  color: #fff !important;
}
